<template>
    <div class="fee-preferences">
        <v-row justify="center">
            <v-col md="8">
                <div v-if="preferences">
                    <div class="ql-editor">
                        <div class="ma-0 mt-2 ql-editor-fees" v-html="preferences.content"></div>

                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Mixins from "@utils/mixins";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { mapGetters } from 'vuex';


export default {
    name: 'FeePreferences',
    mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
    data(){
        return {
            preferences: null,
        }
    },
    computed: {
        ...mapGetters(["currentInstitution"]),

        appBarOptions(){
            return this.currentUser.is_admin ? { 
                iconButton: {
                    icon: "mdi-pencil",
                    action: () => {
                        this.$router.push({ name: 'EditFeePreferences' });
                    },
                }
            } :null
        },
    },
    methods: {
        async getInstitutionFeePreferences() {
            var url = this.endpoints.institutionFeePreferences + this.currentInstitution.id + "/";
            url = this.Helper.addUrlParams(url, ['detailed=true'])
            this.preferences = await this.api.call(this.essentials, url);
        },
    },
    created(){
        this.getInstitutionFeePreferences();
    }
}

</script>

<style>
.ql-editor-fees img { 
    max-width: 100%;
}
.ql-editor{
    width: 100%;
}

</style>